
import { CurrentView } from '~/store/building/-constants'
import { smoothScrollToTargetId } from '~/helpers/util'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'SectionBuilding',
  props: {
    data: {
      required: true,
      type: Object || Array,
      default: function () {
        return {
          backgroundLoader: '',
          disclaimer: '',
          contentLoader: [],
          matterportSpace: []
        }
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    },
    enableEmbedVersion: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFloorView: false,
      loadingScreenActive: true,
      loadingErrorOccurred: false,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      headerHeight: 0,
      embedCartIsOpen: false,
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    }
  },
  computed: {
    isMobileMenuOpen() {
      return this.$store.state.base?.mobileAvailabilityMenuOpen
    },
    contentLoaderData() {
      return this.data?.contentLoader
    },
    backgroundLoaderUrl() {
      return this.data?.backgroundLoader
    },
    videosLoader() {
      return this.data?.videosLoader || []
    },
    currentSpace() {
      return this.$store?.state?.space?.space
    },
    currentSpaceVideoLoaderUrl() {
      if (!this.videosLoader || this.videosLoader.length === 0) return
      const found =
        this.videosLoader.find((v) => v.building_id === this.currentSpace.building_id) || null
      if (!found) return
      return found.video
    },
    isEmbedV2() {
      return false;
    },
    isSpaceEmbedRoute() {
      return this.$route.name === "SpecificSpaceEmbed";
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    totalSpace() {
      let total = 0
      this.requestedSpaces.forEach((s) => {
        total += s.sqm
      })
      return total
    },
    unitOfMeasure() {
      return this.$store.state.base?.meta?.generalConfig?.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base?.meta?.generalConfig?.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    addMatterportClass() {
      return (
        (this.$store.state.base?.meta?.generalConfig?.matterportEnabled && this.isFloorView) ||
        false
      )
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    disableList() {
      return this.projectFeatures?.disableMyList;
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if ([
        'building/setLoadingScreenStatus',
        'building/setCurrentView'
      ].includes(mutation.type)) {
        this.isFloorView = state.building.currentView === CurrentView.FLOOR
        this.loadingScreenActive = state.building.loadingScreenActive
      }
      if ([
        'building/setAssetLoadingError'
      ].includes(mutation.type)) {
        this.loadingErrorOccurred = mutation.payload
      }
    })
    window.addEventListener('resize', this.resizeListener)
    this.headerHeight = document.getElementById('header-project')
      ? document.getElementById('header-project').clientHeight
      : 0
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    goToNextSection() {
      const sections = this.$parent.$parent.sections
      const targetSection = sections[this.orderNo]
      const targetString = targetSection.hashAnchor
      if (sections && sections.length > 1) {
        smoothScrollToTargetId(`#${targetString}`)
      }
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight
      this.headerHeight = document.getElementById('header-project')
        ? document.getElementById('header-project').clientHeight
        : 0
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    },
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    },
    toggleEmbedSidebar() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_MOBILE_AVAILABILITY_MENU))
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE), false)
      this.embedCartIsOpen = false
    },
    toggleCartSidebar() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE), false)
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_MOBILE_AVAILABILITY_MENU), false)
      this.embedCartIsOpen = !this.embedCartIsOpen
    }
  }
}
